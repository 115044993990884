body.webapp-site {
    // tl custom tailwind
    @media screen and (min-width: 991px) {
        mat-stepper {
            &.stepper_full-step {
                div.mat-horizontal-stepper-wrapper, div.mat-horizontal-content-container, div.mat-horizontal-stepper-content {
                    height: 100%;
                    overflow: hidden;
                }
                .mat-horizontal-content-container {
                    width: 100%;
                    position: relative;
                    .mat-horizontal-stepper-content {
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        visibility: hidden;
                    }
                }
            }
        }
    }
    
    // md tailwind
    @media screen and (min-width: 768px) {
        swal2-popup.swal2-modal.alert_table-swal ctt_table table {
            width: 100%;
        }

        // Aumentando tamaños
        & {
            font-size: 1.125rem;
        }

        .payments {
            .title__payments {
                font-size: 1.95rem;
            }
        }
        .mat-mdc-button {
            --mdc-text-button-label-text-size: 1.275rem;
        }
        .cy__btn.mdc-button {
            .mdc-button__label {
                font-size: 1.275rem;
            }
            &.small__btn .mdc-button__label {
                font-size: 1.125rem;
            }
        }
        .text-xl {
            font-size: 1.50rem;
            line-height: 2rem;
        }
        .text-lg {
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
        .text-base {
            font-size: 1.125rem;
            line-height: 1.75rem;
        }
        .text-sm {
            font-size: 1rem;
            line-height: 1.5rem;
        }
        table:not(.custom-table-design):not(.mat-calendar-table) tbody tr td {
            font-size: 1.125rem;

            padding-top: 0.75rem !important;
            padding-bottom: 0.75rem !important;

            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
        .resp__table table:not(.custom-table-design):not(.mat-calendar-table) th {
            font-size: 1.125rem;

            padding-top: 0.75rem !important;
            padding-bottom: 0.75rem !important;

            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }

        .process_cart {
            .subtitle__pc {
                font-size: 1.50rem;
            }
            .title__pc {
                font-size: 1.95rem;
            }
        }
    }

    // sm Tailwind
    @media screen and (min-width: 640px) {
        .content__footer-pay {
            display: none;
        }
        .block__resp {
            display: none;
        }
    }

    @media screen and (min-width: 361px) and (max-width: 767px) {

    }

    @media screen and (min-width: 1024px) {
        // Tailwind lg
        &.hide__menu {
            .hide__menu-desktop {
                grid-template-columns: minmax(0,0) minmax(0,1fr);
            }
        }
    }

    
    @media screen and (max-width: 991px){
    }

    @media screen and (max-width: 767px) {
       
    }

    @media screen and (max-width: 639px) {
        .none__resp {
            display: none;
        }
        .block__resp {
            display: table-cell;
        }
        .cdk-overlay-container .cdk-overlay-connected-position-bounding-box {
            background-color: rgba(0, 0, 0, 0.36);
            width: 100% !important;
            right: 0 !important;
            left: 0 !important;
            .cdk-overlay-pane {
                position: unset;
                margin: auto;
            }
        }
        .content__payment-card {
            aspect-ratio: 1/0.65;
            .first__card {
                .number__card {
                    font-size: 17px;
                }
                .content__logo-type-card .logo__type-card {
                    width: 70px;
                    height: 35px;
                }
            }
            .detail__card {
                .name__card, .date__card {
                    span {
                        font-size: 10px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
            }
            &.card__list, &.card__select-list:not(.selected__card) {
                .first__card {
                    .number__card {
                        width: calc(100% - 40px);
                        font-size: 14px;
                    }
                    .content__logo-type-card .logo__type-card {
                        width: 40px;
                        height: 23px;
                    }
                }
            }
        }
    }


    @media screen and (max-width: 512px){
        .content__info-renov {
            grid-template-columns: minmax(0, max-content) minmax(0, 1fr);
            .content__info-btn {
                margin-top: 1rem;
                text-align: center;
                grid-column: 1 / -1;
            }
        }
    }

    @media screen and (min-width: 991px) {
        .container_cy.cb-auth {
            width: 915px;
        }
    }

    @media screen and (min-width: 1350px) {
        .container_cy.cb-auth {
            width: 1200px;
        }
    }

    @media screen and (min-width: 1536px) {
        .container_cy.cb-auth {
            width: 1440px;
        }
    }

}
